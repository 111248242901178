/**
 * @generated SignedSource<<dabf9b0bf95d0f1ccb1aa8ca21a30073>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type layoutProductQuery$variables = Record<PropertyKey, never>;
export type layoutProductQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"productHeaderUserSectionFragment">;
};
export type layoutProductQuery = {
  response: layoutProductQuery$data;
  variables: layoutProductQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "layoutProductQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "productHeaderUserSectionFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "layoutProductQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserSessionPayload",
        "kind": "LinkedField",
        "name": "userSession",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b9048266bff58e845dca402bc4877757",
    "id": null,
    "metadata": {},
    "name": "layoutProductQuery",
    "operationKind": "query",
    "text": "query layoutProductQuery {\n  ...productHeaderUserSectionFragment\n}\n\nfragment productHeaderUserSectionFragment on Query {\n  userSession {\n    id\n  }\n}\n"
  }
};

(node as any).hash = "51194adca01e03513f2051ca94d100d6";

export default node;
