"use client";

import { MoonIcon, SunIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import cx from "classix";
import { useTheme } from "next-themes";
import { useCallback, useEffect, useState } from "react";
import { OverlayArrow, ToggleButton, Tooltip, TooltipTrigger } from "react-aria-components";
import { useIntl } from "react-intl";

type DarkModeTogglButtonProperties = {
  isDark?: boolean | undefined;
};

export const DarkModeToggleButton = ({ isDark = true }: DarkModeTogglButtonProperties) => {
  const intl = useIntl();
  const { setTheme, theme } = useTheme();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  const toggleDarkMode = useCallback(() => {
    setTheme(theme === "dark" ? "light" : "dark");
  }, [setTheme, theme]);

  return (
    <TooltipTrigger>
      {isReady ? (
        <ToggleButton
          aria-label={intl.formatMessage({
            defaultMessage: "Toggle dark mode",
            id: "Uzzhfy",
          })}
          className={cx(
            isDark
              ? "text-neutral-dark-11 active:bg-neutral-dark-a-5 hover:bg-neutral-dark-a-4 focus-visible:outline-neutral-dark-7 disabled:text-neutral-dark-9"
              : " text-neutral-11 active:bg-neutral-a-5 hover:bg-neutral-a-4 focus-visible:outline-neutral-7 disabled:text-neutral-9",
            "group size-8 cursor-default rounded-md p-2 disabled:pointer-events-none",
          )}
          isSelected={theme === "dark"}
          onChange={toggleDarkMode}
        >
          <MoonIcon className="hidden size-4 group-selected:block" />
          <SunIcon className="block size-4 group-selected:hidden" />
        </ToggleButton>
      ) : (
        <span
          className={cx(
            isDark
              ? "text-neutral-dark-11 active:bg-neutral-dark-a-5 hover:bg-neutral-dark-a-4 focus-visible:outline-neutral-dark-7 disabled:text-neutral-dark-9"
              : "text-neutral-11 active:bg-neutral-a-5 hover:bg-neutral-a-4 focus-visible:outline-neutral-7 disabled:text-neutral-9",
            "group pointer-events-none block size-8 cursor-default rounded-md p-2 disabled:pointer-events-none",
          )}
        />
      )}
      <Tooltip className="group select-none will-change-transform entering:duration-100 entering:animate-in entering:fade-in-0 entering:zoom-in-95 placement-left:entering:slide-in-from-right-1 placement-right:entering:slide-in-from-left-1 placement-top:entering:slide-in-from-bottom-1 placement-bottom:entering:slide-in-from-top-1 exiting:duration-75 exiting:animate-out exiting:fade-out-0 exiting:zoom-out-95 placement-left:exiting:slide-out-to-right-1 placement-right:exiting:slide-out-to-left-1 placement-top:exiting:slide-out-to-bottom-1 placement-bottom:exiting:slide-out-to-top-1">
        <div className="rounded-sm bg-white-a-12 p-1.5 text-xs leading-none text-black-a-12 shadow-sm group-placement-left:-translate-x-3 group-placement-right:translate-x-3 group-placement-top:-translate-y-3 group-placement-bottom:translate-y-3">
          <OverlayArrow>
            <TriangleDownIcon className="block size-5 text-white-a-12 group-placement-left:-ml-2 group-placement-left:-rotate-90 group-placement-right:-mr-2 group-placement-right:rotate-90 group-placement-top:-mt-2 group-placement-bottom:-mb-2 group-placement-bottom:rotate-180" />
          </OverlayArrow>
          {intl.formatMessage({
            defaultMessage: "Toggle dark mode",
            id: "Uzzhfy",
          })}
        </div>
      </Tooltip>
    </TooltipTrigger>
  );
};
