/* eslint-disable react/jsx-props-no-spreading */
import { forwardRef, SVGAttributes } from "react";

export const Logo = forwardRef<SVGSVGElement, SVGAttributes<SVGElement>>((properties, reference) => (
  <svg
    fill="currentColor"
    ref={reference}
    viewBox=".03 0 31.94 32"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path d="M2.77 7C5.65 2.774 10.5 0 16 0s10.35 2.774 13.23 7H2.77ZM1.735 8.747A15.905 15.905 0 0 0 .03 15h31.938a15.906 15.906 0 0 0-1.704-6.253A.997.997 0 0 1 29.6 9H2.4a.996.996 0 0 1-.665-.253Zm0 14.506A15.906 15.906 0 0 1 .03 17h31.938a15.906 15.906 0 0 1-1.704 6.253A.997.997 0 0 0 29.6 23H2.4a.996.996 0 0 0-.665.253ZM2.77 25c2.88 4.226 7.73 7 13.23 7s10.35-2.774 13.23-7H2.77Z" />
  </svg>
));
