"use client";

import { ArrowRightIcon } from "@radix-ui/react-icons";
import Link from "next/link";
import { useIntl } from "react-intl";
import { graphql, useFragment } from "react-relay";
import { productHeaderUserSectionFragment$key as ProductHeaderUserSectionFragment$key } from "@/relay/__generated__/productHeaderUserSectionFragment.graphql";

export const LoadingProductHeaderUserSection = () => null;

export const LoadedProductHeaderUserSection = (properties: {
  queryReference: ProductHeaderUserSectionFragment$key;
}) => {
  const { queryReference } = properties;
  const intl = useIntl();

  const fragmentResult = useFragment(
    graphql`
      fragment productHeaderUserSectionFragment on Query {
        userSession {
          id
        }
      }
    `,
    queryReference,
  );

  const hasSession = fragmentResult.userSession != null;

  if (hasSession) {
    return (
      <div className="flex flex-1 items-center justify-end gap-x-6">
        <Link
          className="inline-flex items-center rounded-md text-sm font-semibold leading-6 text-primary-12"
          href="/console"
        >
          {intl.formatMessage({
            defaultMessage: "Console",
            id: "gc+2Z2",
          })}
          <ArrowRightIcon className="relative start-1 size-5 rtl:-scale-x-100" />
        </Link>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center justify-end gap-x-6">
      <Link className="rounded-md text-sm font-semibold leading-6 text-primary-12" href="/sign-in">
        {intl.formatMessage({
          defaultMessage: "Sign in",
          id: "SQJto2",
        })}
      </Link>
      <Link
        className="rounded-md px-3 py-1.5 text-sm font-semibold text-primary-12 ring-1 ring-primary-7 active:bg-primary-5 hover:border-primary-8 hover:bg-primary-4"
        href="/sign-up"
      >
        {intl.formatMessage({
          defaultMessage: "Sign up",
          id: "8HJxXG",
        })}
      </Link>
    </div>
  );
};
