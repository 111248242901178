/**
 * @generated SignedSource<<dbd7dfecf1c82ed20ff86e56ba9dcf7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productHeaderUserSectionFragment$data = {
  readonly userSession: {
    readonly id: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "productHeaderUserSectionFragment";
};
export type productHeaderUserSectionFragment$key = {
  readonly " $data"?: productHeaderUserSectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productHeaderUserSectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "productHeaderUserSectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserSessionPayload",
      "kind": "LinkedField",
      "name": "userSession",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "01840acf0c03ad57229c3a8d84e64c45";

export default node;
